.partners-wrapper {
  padding: 180px 0;

  .partners-section {
    padding: 30px 0;
    background-image: url(../../assets/partners_word.svg);
    background-position: left;
    background-repeat: no-repeat;
    overflow: hidden;
    @include media("<=tablet") {
      margin: 80px 0;
    }

    .partners-items {
      width: 100%;
      display: flex;

      &:hover .partners-items-loop {
        -webkit-animation-play-state: paused;
        -moz-animation-play-state: paused;
        -o-animation-play-state: paused;
        animation-play-state: paused;
      }
      .partners-items-loop {
        display: flex;

        -webkit-animation: logoloop 70s linear infinite;
        -moz-animation: logoloop 70s linear infinite;
        -o-animation: logoloop 70s linear infinite;

        @include media("<=phone") {
          -webkit-animation-duration: 30s;
          -moz-animation-duration: 30s;
          -o-animation-duration: 30s;
        }

        .partner {
          display: flex;
          align-items: center;
          .partnerImage {
            width: 200px;
            filter: saturate(0%) contrast(0%) brightness(0.5);
            margin: 0 72px;
            transition: 0.3s;

            @include media("<=phone") {
              width: 120px;
              margin: 0 24px;
            }

            &:hover {
              filter: none;
              transition: 0.3s;
            }
          }
        }
      }
    }
  }
}

@-webkit-keyframes logoloop {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@-moz-keyframes logoloop {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@-o-keyframes logoloop {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes logoloop {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}
