.main-banner {
  height: 120vh;
  .banner-title {
    height: 60vh;
    height: calc(var(--vh, 1vh) * 60);
    background-color: $white;
    @include media("<=tablet") {
      /*  height: 50vh;
      height: calc(var(--vh, 1vh) * 50); */
    }
    .row {
      flex-direction: column;
      justify-content: center;
      h2 {
        font-size: 4rem;
        font-weight: normal;
        text-align: center;
        z-index: 10;
        color: $white;
        mix-blend-mode: difference;
        padding-top: 128px;

        @include media("<=desktop") {
          font-size: calc(23px + 2vw); /* 50588vw */
        }
        @include media("<=phone") {
          padding-top: 60px;
          /* text-align: left; */
        }
        .line {
          margin-bottom: 8px;
          position: relative;
          overflow: hidden;
          height: 90px;

          @include media("<=desktop") {
            height: 60px;
          }
          @include media("<=SMdesktop") {
            height: 54px;
          }
          @include media("<tablet") {
            height: 87px;
          }
          @include media("<=phone") {
            height: 77px;
          }
          span {
            position: absolute;
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
  .banner-image {
    height: 60vh;
    height: calc(var(--vh, 1vh) * 60);
    z-index: 3;
    /* background-image: url(../../assets/herobg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    position: absolute;
    top: 60vh;
    left: 0;
    right: 0;
    padding-left: calc(16.4px + 2vw);
    padding-right: calc(16.4px + 2vw);
    @include media("<=tablet") {
      /* height: 50vh;
      height: calc(var(--vh, 1vh) * 50); */

      /* padding-left: calc(16.4px + 0.3vw);
      padding-right: calc(16.4px + 0.3vw); */
    }
    .hero-media {
      background-color: $grey;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  .scroll-fixed-text {
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    text-align: center;
    position: fixed;
    right: 20px;
    top: 50%;
    transform: rotate(90deg);
    z-index: inherit;
    z-index: 2;
    @include media("<=SMdesktop") {
      left: 0;
      right: 0;
      transform: none;
      display: none;
    }
  }
}
