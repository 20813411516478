.footer {
  padding: 100px 0;
  background-color: $light-grey;
  position: relative;
  z-index: 4;
  @include media("<=tablet") {
    padding: 50px 0;
  }
  .footer-sections {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .links {
    list-style-type: none;
    padding: 0;
    a {
      text-decoration: none;
      color: $black;
    }
  }
  .financiers {
    width: 300px;
    margin-top: 20px;
    @include media("<=phone") {
      width: 270px;
    }
  }
}
