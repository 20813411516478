@import url("https://fonts.googleapis.com/css?family=Poppins");
@import "./variables.scss";
@import "./breakpoints.scss";
@import "./components/header.scss";
@import "./components/footer.scss";
@import "./components/banner.scss";
@import "./components/navigation.scss";
@import "./components/partners.scss";

body,
html {
  visibility: hidden;
  user-select: none;
  background: $white;
  color: $black;
  overscroll-behavior: none;
  margin: 0;
  height: 100%;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 0.5em;
  background: transparent;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 0;
  border-radius: 500px;
}

.App {
  z-index: 4;
  background: $white;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.container {
  width: 1560px;
  min-width: 1560px;
  margin: 0 auto;
  height: 100%;
  @include media("<=1560px") {
    width: 1280px;
    min-width: 1280px;
  }
  @include media("<=desktop") {
    width: 1080px;
    min-width: 1080px;
  }
  @include media("<=1080px") {
    width: 100%;
    min-width: 100%;
  }
  .row {
    display: flex;
    height: 100%;
    padding: 0 32px;
    @include media("<=phone") {
      padding: 0 16px;
    }
  }
  .v-center {
    align-items: center;
  }
  .space-between {
    justify-content: space-between;
  }
}

.container-fluid {
  width: 100%;
  height: 100%;
  .row {
    display: flex;
    height: 100%;
  }
}

.main {
  padding: 168px 0;
}

.title {
  font-size: 3rem;
  color: $black;
}

.title-upper {
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
}

.subtitle {
  font-size: 2.5rem;
}

.normal-text {
  font-size: 1.5rem;
}

.medium-text {
  font-size: 2rem;
}

.text-bold {
  font-weight: bold;
}

.home-body {
  .home-project {
    padding-top: 180px;
    @include media("<=tablet") {
      padding-top: 90px;
    }
  }
  .home-features {
    padding-top: 180px;
    @include media("<=tablet") {
      padding-top: 100px;
    }
    .panel-composition {
      position: relative;
      .img-panel {
        padding-right: 40px;
        padding-top: 50px;
        width: 100%;
        object-fit: cover;
      }
      .circular-comsolve {
        position: absolute;
        top: 0;
        right: 0;
        -webkit-animation: spin 8s linear infinite;
        -moz-animation: spin 8s linear infinite;
        animation: spin 8s linear infinite;

        @include media("<=tablet") {
          right: 10px;
        }
      }
    }
    .features-list {
      display: flex;
      flex-direction: column;
      .features-item {
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.circle-link {
  /* background-color: $grey; */
  text-decoration: none;
  color: $black;
  font-weight: 400;
  position: relative;
  width: 160px;
  height: 50px;
  padding: 15px 30px;
  .circle {
    position: absolute;
    border: 2px solid $yellow;
    height: 50px;
    width: 50px;
    left: 0;
    top: 50%;
    transform: translate(0%, -50%);
    border-radius: 100px;
    z-index: 0;

    transition: 0.3s ease-in-out;
  }
  .link {
    z-index: 1;
    /* position: absolute; */
  }

  &:hover {
    color: $black;
    .circle {
      width: 100%;
      transition: 0.3s ease-in-out;
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.features {
  margin: 80px 0;
  .feature-item {
    padding-bottom: 20px;
    .description {
      padding-left: 30px;
    }
  }
}

.news {
  margin: 80px 0;
  .news-item {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 60px;
    border: 1px solid lightgray;

    @include media("<=tablet") {
      flex-direction: column-reverse;
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 15px;

      a {
        text-decoration: none;
        transition: all 0.3s;
        font-size: 1.7rem;

        &:hover {
          color: $yellow;
        }

        @include media("<=tablet") {
          font-size: 1.5rem;
        }
      }

      .description {
        margin-top: 20px;
        flex: 1;
      }

      .from {
        text-transform: uppercase;

        @include media("<=tablet") {
          align-self: flex-end;
          margin-top: 20px;
        }
      }
    }

    .cover {
      img {
        width: 250px;
        height: 250px;
        object-fit: cover;

        @include media("<=tablet") {
          width: 100%;
        }
      }
    }
  }
}

/* Intro Overlay */
.intro-overlay {
  .top {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: absolute;
    width: 100%;
    z-index: 8;
    .overlay-top {
      position: absolute;
      height: 100%;
      /* width: 100%; */
      background: $dark-black;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

/* Page Transition Overlay */
.page-transition-overlay {
  position: fixed;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  z-index: 11;
  background: $dark-black;
}
