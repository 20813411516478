.header {
  position: fixed;
  width: 100%;
  height: 128px;
  z-index: 11;
  @include media("<=phone") {
    height: 96px;
  }
  .logo {
    a {
      img {
        /* &:hover {
          filter: brightness(0) invert(1);
        } */
      }
    }
  }
  .nav-toggle {
    cursor: pointer;
    position: relative;
    justify-content: center;
    align-items: center;
    display: none;
    @include media("<=tablet") {
      margin-right: 24px;
    }
    @include media("<=phone") {
      margin-right: 16px;
      width: 20px;
      display: flex;
    }
    .hamburger-menu {
      width: 35px;
      span {
        margin-bottom: 0.5rem;
        background: $dark-black;
        height: 2px;
        width: 100%;
        display: block;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .hamburger-menu-close {
      position: absolute;
      display: none;
      svg {
        @include media("<=tablet") {
          width: 56px;
        }
        @include media("<=phone") {
          width: 48px;
        }
      }
    }
  }

  .nav-menu-links {
    @include media("<=phone") {
      display: none;
    }
    a {
      text-decoration: none;
      color: $dark-black;
      margin-right: 2rem;
      font-size: 1.2rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
