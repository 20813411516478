nav {
  left: 0;
  top: -70vh;
  height: 70vh;
  width: 100%;
  z-index: 11;
  display: none;
  position: fixed;
  overflow: auto;
  background: $light-grey;
  color: $black;
  @include media("<=tablet") {
    top: -100vh;
    height: 100vh;
  }
  .nav-columns {
    margin: 150px 0 0 0;
    display: flex;
    padding: 0 32px;
    flex-direction: column;
    @include media("<=2.") {
      flex-direction: column;
      padding: 0 16px;
      height: 100vh;
      margin: 120px 0;
    }
    .nav-column {
      display: flex;
      align-items: flex-end;
      width: 45%;
      @include media("<=tablet") {
        width: 100%;
      }
      &:last-child {
        width: 55%;
        @include media("<=tablet") {
          width: 100%;
        }
      }
      .nav-links {
        padding: 0;
        margin: 0;
        li {
          list-style: none;
          margin-top: 1rem;
          a {
            font-size: 2.4rem;
            text-decoration: none;
            color: $black;
          }
        }
      }
      .nav-infos {
        @include media("<=tablet") {
          padding-top: 80px;
        }
        .nav-info {
        }
      }
    }
  }
}
